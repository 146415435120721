import { useCallback, useEffect, useRef } from "react";
import { MediaSlide, useLightboxState } from "yet-another-react-lightbox";

import ImageResponsive from "~/components/atoms/ImageResponsive";
import Media from "~/components/atoms/Media";
import { IMediaImageUrls, IMediaVideoUrls, TMedia } from "~/entities/media";
import { cn } from "~/utilities/cn";
import { EMedia } from "~/utilities/enums/Media";

export interface MediaSlideComponentProps {
  slide: MediaSlide;
  zoomLevel: number;
}

const THRESHOLD_MOVE = 0.5;

const MediaSlideComponent: React.FC<MediaSlideComponentProps> = ({
  slide,
  zoomLevel,
}) => {
  const { currentIndex } = useLightboxState();
  const containerRef = useRef<HTMLDivElement | null>(null);

  // Use refs instead of state to store drag positions
  const dragPosition = useRef({ x: 0, y: 0 });
  const startDrag = useRef({ x: 0, y: 0 });
  const isDragging = useRef(false);

  const isZooming = zoomLevel > 1;

  const updateTransform = useCallback(() => {
    if (containerRef.current) {
      containerRef.current.style.transform = `scale(${zoomLevel}) translate(${
        dragPosition.current.x * THRESHOLD_MOVE
      }px, ${dragPosition.current.y * THRESHOLD_MOVE}px)`;
    }
  }, [zoomLevel]);

  const handleStart = useCallback((e: MouseEvent | TouchEvent) => {
    e.preventDefault();

    const startX = e instanceof MouseEvent ? e.clientX : e.touches[0].clientX;
    const startY = e instanceof MouseEvent ? e.clientY : e.touches[0].clientY;

    isDragging.current = true;
    startDrag.current = {
      x: startX - dragPosition.current.x,
      y: startY - dragPosition.current.y,
    };
  }, []);

  const handleMove = useCallback(
    (e: MouseEvent | TouchEvent) => {
      if (!isDragging.current || zoomLevel <= 1) return;

      const moveX = e instanceof MouseEvent ? e.clientX : e.touches[0].clientX;
      const moveY = e instanceof MouseEvent ? e.clientY : e.touches[0].clientY;

      dragPosition.current = {
        x: moveX - startDrag.current.x,
        y: moveY - startDrag.current.y,
      };

      requestAnimationFrame(updateTransform);
    },
    [updateTransform, zoomLevel]
  );

  const handleEnd = useCallback(() => {
    isDragging.current = false;
  }, []);

  useEffect(() => {
    dragPosition.current = { x: 0, y: 0 };
    startDrag.current = { x: 0, y: 0 };
    updateTransform();
  }, [zoomLevel, updateTransform]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    container.addEventListener("mousedown", handleStart);
    container.addEventListener("mousemove", handleMove);
    container.addEventListener("mouseup", handleEnd);
    container.addEventListener("mouseleave", handleEnd);
    container.addEventListener("touchstart", handleStart, { passive: false });
    container.addEventListener("touchmove", handleMove, { passive: false });
    container.addEventListener("touchend", handleEnd);

    return () => {
      container.removeEventListener("mousedown", handleStart);
      container.removeEventListener("mousemove", handleMove);
      container.removeEventListener("mouseup", handleEnd);
      container.removeEventListener("mouseleave", handleEnd);
      container.removeEventListener("touchstart", handleStart);
      container.removeEventListener("touchmove", handleMove);
      container.removeEventListener("touchend", handleEnd);
    };
  }, [handleStart, handleMove, handleEnd]);

  if (!slide.media || slide.index !== currentIndex) return null;

  return (
    <>
      {slide.mediaType === EMedia.IMAGE ? (
        <div className="h-full w-auto overflow-hidden" ref={containerRef}>
          <ImageResponsive
            style={{ transition: "transform 0.1s ease-out" }} // Smooth movement
            imageData={slide.media as IMediaImageUrls}
            className={cn(
              `relative h-full w-auto object-contain`,
              isZooming
                ? isDragging.current
                  ? "cursor-grabbing"
                  : "cursor-grab"
                : ""
            )}
            draggable="false"
            displayDisclaimer={true}
            pictureClassName="h-fit"
          />
        </div>
      ) : (
        <Media
          media={slide.media as IMediaVideoUrls}
          className="mx-auto aspect-[16/9] h-auto w-full object-contain"
        />
      )}
    </>
  );
};

export default MediaSlideComponent;

declare module "yet-another-react-lightbox" {
  export interface MediaSlide extends GenericSlide {
    type: "media-slide";
    mediaType: EMedia.IMAGE | EMedia.VIDEO;
    index: number;
    media: TMedia;
  }

  interface SlideTypes {
    "media-slide": MediaSlide;
  }
}
