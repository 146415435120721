import { useTranslation } from "react-i18next";

import Typo from "~/components/atoms/Typo";
import { cn } from "~/utilities/cn";
import { ETypoColor } from "~/utilities/enums/Colors";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface CardProjectDetailProps {
  className?: string;
  projectName?: string;
  classNameRows?: string;
  projectDescription: {
    location: string;
    address: string;
    googleMapUrl: string;
    developer: string;
    totalUnits: string;
    tenure: string;
    expectedTop: string;
    projectCategory: string;
  };
}

export default function CardProjectDetail({
  className = "",
  projectDescription,
  projectName,
  classNameRows = "",
}: CardProjectDetailProps) {
  return (
    <div
      className={cn(
        "flex flex-col gap-6 rounded-xl px-0 py-6 lg:gap-5 lg:rounded-lg lg:px-6 lg:py-4.5",
        className
      )}
    >
      {projectName && (
        <Typo
          className="font-bold"
          variant={ETypoVariant.HEADER_PROJECT_24}
          color={ETypoColor.PROJECT_HEADING}
          tag={ETypoTag.H3}
        >
          {projectName}
        </Typo>
      )}

      <div
        className={cn(
          "grid grid-cols-3 gap-x-12 gap-y-3 last:!col-span-full lg:grid-cols-2 lg:gap-x-5 lg:gap-y-4.5",
          classNameRows
        )}
      >
        <ProjectDetailRow
          title="titles.location"
          desc={projectDescription?.location}
        />
        <ProjectDetailRow
          title="titles.address"
          desc={projectDescription?.address}
        />
        <ProjectDetailRow
          title="titles.developer"
          desc={projectDescription?.developer}
        />
        <ProjectDetailRow
          title="titles.total_units"
          desc={projectDescription?.totalUnits}
        />
        <ProjectDetailRow
          title="titles.tenure"
          desc={projectDescription?.tenure}
        />
        <ProjectDetailRow
          title="titles.expectedTOP"
          desc={projectDescription?.expectedTop}
        />
        <ProjectDetailRow
          className="col-span-full"
          title="titles.project_category"
          desc={projectDescription?.projectCategory}
        />
      </div>
    </div>
  );
}

const ProjectDetailRow = ({
  title,
  desc,
  className,
}: {
  title: string;
  desc: string;
  className?: string;
}) => {
  const { t } = useTranslation();

  return (
    <div className={cn("col-span-1 flex flex-col gap-2", className)}>
      <Typo
        tag={ETypoTag.H4}
        variant={ETypoVariant.SUB_TITLE_16}
        color={ETypoColor.TEXT}
        className="pj-detail-cell-label font-bold opacity-25"
      >
        {t(title)}
      </Typo>
      <Typo
        className="pj-detail-cell-desc"
        tag={ETypoTag.P}
        variant={ETypoVariant.BODY_TITLE_16}
      >
        {desc}
      </Typo>
    </div>
  );
};
