import { useFetcher } from "@remix-run/react";
import isEmpty from "lodash/isEmpty";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RemixFormProvider, useRemixForm } from "remix-hook-form";

import MultiSelect from "~/components/atoms/SelectBox/MultiSelect";
import Typo from "~/components/atoms/Typo";
import ProjectFloorPlans from "~/components/organisms/ProjectFloorPlans";
import Section from "~/components/templates/SectionProject";
import { IFloorPlan, IFloorPlanFilterOption } from "~/entities/floorPlan";
import { IProject } from "~/entities/project";
import { IPaginateResponseData } from "~/types";
import { TProjectLoaderData } from "~/types/projectLoaderData";
import { cn } from "~/utilities/cn";
import { LIMIT, PAGE } from "~/utilities/constants/common";
import { EApiParam } from "~/utilities/enums/ApiParam";
import { ETypoColor } from "~/utilities/enums/Colors";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface SectionProjectFloorPlansProps {
  className?: string;
  id: string;
  title: string;
  description: string;
  project: IProject;
  floorPlansPagination: IPaginateResponseData<IFloorPlan>;
  unitTypeFilterOptions: IFloorPlanFilterOption[];
}

const SectionProjectFloorPlans: React.FC<SectionProjectFloorPlansProps> = ({
  className = "",
  id,
  title,
  description,
  floorPlansPagination,
  unitTypeFilterOptions,
}) => {
  const { t } = useTranslation();
  const fetcher = useFetcher<TProjectLoaderData>();
  const form = useRemixForm({ mode: "onChange" });

  const unitTypes =
    unitTypeFilterOptions?.map((item) => ({
      name: `${item.title} (${item?.floorPlanCount || 0})`,
      id: item.id,
    })) || [];

  const floorPlanFilter: string[] = form.watch(EApiParam.UNITTYPEIDS);

  useEffect(() => {
    if (floorPlanFilter) {
      const formData = new FormData();
      formData.append(EApiParam.UNITTYPEIDS, floorPlanFilter.join(","));
      formData.append(EApiParam.PAGE, `${PAGE}`);
      formData.append(EApiParam.LIMIT, `${LIMIT}`);
      fetcher.submit(formData, {
        method: "GET",
        preventScrollReset: true,
      });
    }
  }, [floorPlanFilter]);

  const total = useMemo(() => {
    return !isEmpty(floorPlanFilter)
      ? unitTypeFilterOptions
          ?.filter((option) => floorPlanFilter?.includes(option?.id))
          ?.reduce((pre, cur) => pre + Number(cur?.floorPlanCount ?? 0), 0) || 0
      : floorPlansPagination?.total || 0;
  }, [
    floorPlanFilter,
    unitTypeFilterOptions,
    fetcher?.data,
    floorPlansPagination?.total,
  ]);

  const onLoadMore = () => {
    if (
      fetcher.state === "loading" ||
      (typeof fetcher?.data?.floorPlansPagination?.currentPage === "number" &&
        typeof fetcher?.data?.floorPlansPagination?.totalPage === "number" &&
        fetcher?.data?.floorPlansPagination?.currentPage ===
          fetcher?.data?.floorPlansPagination?.totalPage) ||
      (typeof floorPlansPagination?.currentPage === "number" &&
        typeof floorPlansPagination?.totalPage === "number" &&
        floorPlansPagination?.currentPage === floorPlansPagination?.totalPage)
    )
      return;

    const page = fetcher?.data?.floorPlansPagination?.currentPage
      ? fetcher?.data?.floorPlansPagination?.currentPage + 1
      : floorPlansPagination?.currentPage + 1;
    const limit = fetcher?.data?.floorPlansPagination?.limit
      ? fetcher?.data?.floorPlansPagination?.limit
      : floorPlansPagination?.limit;

    const formData = new FormData();
    if (floorPlanFilter) {
      formData.append(EApiParam.UNITTYPEIDS, floorPlanFilter.join(","));
    }
    formData.append(EApiParam.PAGE, `${page}`);
    formData.append(EApiParam.LIMIT, `${limit}`);
    fetcher.submit(formData, {
      method: "GET",
      preventScrollReset: true,
    });
  };

  return (
    <Section id={id} className={className}>
      <div
        className={cn(
          "flex flex-col gap-y-6 rounded-xl py-6 lg:gap-y-4 lg:rounded-lg lg:py-4.5",
          className
        )}
      >
        <div className="flex items-center justify-between px-0 lg:flex-col lg:items-start lg:gap-y-4 lg:px-6">
          <div className="unit-analysis-title flex-1 space-y-2">
            <Typo
              className="font-bold"
              variant={ETypoVariant.HEADER_PROJECT_24}
              tag={ETypoTag.H3}
              color={ETypoColor.PROJECT_HEADING}
            >
              {t(title)}
            </Typo>
            {description && (
              <Typo variant={ETypoVariant.SUB_TITLE_16} tag={ETypoTag.SPAN}>
                {t(description, {
                  total: total,
                })}
              </Typo>
            )}
          </div>
          {unitTypes && (
            <div className="mt-auto flex-1 lg:w-full">
              <RemixFormProvider {...form}>
                <MultiSelect
                  data={unitTypes}
                  name={EApiParam.UNITTYPEIDS}
                  placeholder={t("select_floor_plan")}
                  title=""
                  dropdownClass="w-[var(--button-width)]"
                />
              </RemixFormProvider>
            </div>
          )}
        </div>
        <ProjectFloorPlans
          floorPlanData={
            fetcher?.data?.floorPlansPagination?.items ||
            floorPlansPagination?.items ||
            []
          }
          page={fetcher?.data?.floorPlansPagination?.currentPage || 1}
          onLoadMore={onLoadMore}
          isLoading={fetcher.state === "loading"}
        />
      </div>
    </Section>
  );
};

export default SectionProjectFloorPlans;
